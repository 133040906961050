import constructionDoge from "./assets/construction_doge.jpg";

function App() {

  
  return (
    <div className="App">
      <div className="container">
        <div className="home">
          <h1 className="">brb...</h1>
          <h3 className="mb-20">under construction</h3>
          <div className="">
            <img src={constructionDoge} alt="construction doge" />
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;
